import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import SliceZone from '../components/SliceZone';
import { useSetRecoilState } from 'recoil';
import {
  themeConfigState,
  seoDataState,
  topMenuDataState,
  footerDataState,
} from '../../common/States';

export const query = graphql`
  query PageQuery($id: String) {
    prismic {
      allPages(id: $id) {
        edges {
          node {
            body {
              ... on PRISMIC_PageBodyHero {
                type
                label
                fields {
                  button_align
                  button_color
                  button_link_type
                  button_size
                  button_text
                  button_variant
                  tag_line
                  tag_line_align_text
                  tag_line_color
                  tag_line_pb
                  tag_line_pt
                }
                primary {
                  background_image
                  heading
                  heading_align_text
                  heading_color
                  heading_pb
                  heading_pt
                  image_object_position
                  image_overlay_color
                  image_overlay_opacity
                  min_height
                  show_search_bar
                  background_fixed
                  justify_content
                }
              }
              ... on PRISMIC_PageBodyHome_carousel {
                type
                fields {
                  align_text
                  button_align
                  button_colour
                  button_link_type
                  button_size
                  button_text
                  button_variant
                  image
                  image_object_position
                  image_overlay_color
                  image_overlay_opacity
                  justify_content
                  text
                  text_color
                }
                primary {
                  auto_play
                  interval
                  min_height
                  default_height
                  with_login_type
                  repeat_image
                  show_indicators
                }
              }
              ... on PRISMIC_PageBodySmall_banner {
                type
                label
                primary {
                  background_color
                  content
                  text_color
                  padding
                  show_on_mobile
                }
              }
              ... on PRISMIC_PageBodyCard_grid {
                type
                fields {
                  align_text
                  background_color
                  heading
                  image
                  text
                  text_color
                }
              }
              ... on PRISMIC_PageBodyInformation_grid {
                type
                primary {
                  background_color
                  footer
                  heading
                  text_color
                }
                fields {
                  align_text
                  heading
                  heading_color
                  image
                  text
                  text_color
                  crop_image
                }
              }
              ... on PRISMIC_PageBodyInfo_with_background_image {
                type
                primary {
                  align_text
                  background_color
                  background_image
                  text
                  text_color
                  background_overlay_color
                  background_overlay_opacity
                }
              }
              ... on PRISMIC_PageBodyText_section {
                type
                fields {
                  align_text
                  padding
                  text
                  text_color
                  max_width
                }
                primary {
                  background_color
                  padding
                }
              }
              ... on PRISMIC_PageBodySplit_info_and_image {
                type
                primary {
                  background_color
                  button_align
                  button_color
                  button_link_type
                  button_size
                  button_text
                  button_variant
                  image
                  show_image_on_right
                  text
                  text_color
                }
              }
              ... on PRISMIC_PageBodyCarousel {
                type
                primary {
                  animation_type
                  auto_play
                  interval
                  min_height
                  nav_buttons_always_visible
                  show_full_height_hover
                  show_indicators
                }
                fields {
                  align_text
                  button_align
                  button_color
                  button_link_type
                  button_size
                  button_text
                  button_variant
                  image
                  image_object_position
                  image_overlay_color
                  image_overlay_opacity
                  justify_content
                  text
                  text_color
                }
              }
            }
            description
            title
          }
        }
      }
      allThemes {
        edges {
          node {
            dark_background
            dark_text
            error_color
            info_color
            light_background
            warning_color
            light_text
            primary_background
            primary_color
            primary_text
            secondary_background
            secondary_color
            secondary_text
            success_color
            link_primary_color
            link_hover_color
            nav_link_color
            nav_link_hover_color
            nav_link_active_color
          }
        }
      }
      allTop_menu_navigations {
        edges {
          node {
            logo_image
            color
            nav_links_color
            nav_links_hover_color
            nav_links_active_color
            position
            menu_buttons {
              variant
              link {
                ... on PRISMIC_Home_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signup_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signin_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Page {
                  _meta {
                    uid
                  }
                }
              }
              text
              color
              size
            }
            navigation_links {
              link {
                ... on PRISMIC_Home_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signup_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signin_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Page {
                  _meta {
                    uid
                  }
                }
              }
              text
              underline
              variant
            }
          }
        }
      }
      allFooters {
        edges {
          node {
            background_color
            copy_right_text
            facebook_link {
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            instagram_link {
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            site_map {
              label
              link_group
              link {
                ... on PRISMIC_Page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Home_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signup_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signin_page {
                  _meta {
                    uid
                  }
                }
              }
            }
            text_color
          }
        }
      }
    }
  }
`;

const PageTemplate = props => {
  const setThemeConfig = useSetRecoilState(themeConfigState);
  const setSeoData = useSetRecoilState(seoDataState);
  const setTopMenuData = useSetRecoilState(topMenuDataState);
  const setFooterDataState = useSetRecoilState(footerDataState);
  const themeFirstEdge = props.data.prismic.allThemes.edges.slice(0, 1).pop();
  const themeConfig = themeFirstEdge.node;
  const pageFirstEdge = props.data.prismic.allPages.edges.slice(0, 1).pop();

  if (!pageFirstEdge) {
    return null;
  }

  const page = pageFirstEdge.node;
  const topMenuFirstEdge = props.data.prismic.allTop_menu_navigations.edges
    .slice(0, 1)
    .pop();
  const topMenuData = topMenuFirstEdge.node;

  const footerFirstEdge = props.data.prismic.allFooters.edges.slice(0, 1).pop();
  const footerData = footerFirstEdge.node;

  useEffect(() => {
    setThemeConfig(themeConfig);
    setSeoData({ title: page.title, description: page.description });
    setTopMenuData(topMenuData);
    setFooterDataState(footerData);
  }, []);

  return (
    <>
      <SliceZone body={page.body} />
    </>
  );
};

export default React.memo(PageTemplate);
